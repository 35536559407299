<template>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div class="mb-5 box-publicacion" data-aos="fade-up">
      <div class="portada">
        <router-link :to="'/convocatoria/' + convocatoriaData.url">
          <img 
            v-lazy="convocatoriaData.imagen" 
            class="img-fluid img-responsive rounded" 
            :alt="convocatoriaData.titulo" 
            :title="convocatoriaData.titulo"
          />
        </router-link>        
      </div>
      <div class="body">
        
          <small class="date">
            <i class="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z" fill="#666666"></path></svg></i> 
            {{convocatoriaData.publicado}}
          </small>
        
        <p class="font-weight-bold text-uppercase text-justify">
          <router-link :to="'/convocatoria/' + convocatoriaData.url">
            {{ convocatoriaData.titulo }}
          </router-link>          
        </p>
        <b-media no-body>
          <b-media-aside class="me-1">
            <b-avatar rounded variant="primary" size="40">
              <feather-icon icon="CalendarIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
            <strong>
              Del {{ convocatoriaData.fecha_ini }} Al
              {{ convocatoriaData.fecha_fin }} 
              </strong>  
            </h6>
            <span>Fecha de Presentación</span>
          </b-media-body>
        </b-media>
        <b-media no-body v-if="convocatoriaData.nombre_lugar">
          <b-media-aside class="me-1">
            <b-avatar rounded variant="primary" size="40">
              <feather-icon icon="MapPinIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ convocatoriaData.nombre_lugar }}
            </h6>
            <small>
              {{ convocatoriaData.direccion_presentacion }},
            </small>
          </b-media-body>
        </b-media>
        <b-media no-body v-if="convocatoriaData.telefono_informes">
          <b-media-aside class="me-1">
            <b-avatar rounded variant="primary" size="40">
              <feather-icon icon="PhoneIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ convocatoriaData.telefono_informes }}
            </h6>
            <span>&nbsp;</span>
          </b-media-body>
        </b-media>
        <b-media no-body v-if="convocatoriaData.url_formulario">
          <b-media-aside class="me-1">
            <b-avatar rounded variant="primary" size="40">
              <feather-icon icon="LinkIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              <a :href="convocatoriaData.url_formulario" target="_blank" rel="noopener noreferrer">Enlace Formulario</a>
            </h6>
          <span>&nbsp;</span>
          </b-media-body>
        </b-media>
        <b-media no-body v-if="convocatoriaData.url_documento">
          <b-media-aside class="me-1">
            <b-avatar rounded variant="primary" size="40">
              <feather-icon icon="BookOpenIcon" size="20" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              <a :href="convocatoriaData.url_documento" target="_blank" rel="noopener noreferrer">Enlace Documento</a>
            </h6>
          <span>&nbsp;</span>
          </b-media-body>
        </b-media>
      </div>
    </div>
  </div>
  
</template>

<script>
import imageDefault from "/src/assets/images/img-default.webp"

export default {
  components: {},
  props: ['convocatoriaData'],
  data() {
    return {
      listData: [
        { text: "Cras justo odio" },
        { text: "Dapibus ac facilisis in" },
        { text: "Vestibulum at eros" },
      ],
      kitchenSinkList: [
        { text: "Cras justo odio" },
        { text: "Vestibulum at eros" },
      ],
    };
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = imageDefault;      
    }
  },
  filters: {
    loadImgOrPlaceholder: function (path, fallback) {
      let img = ""
      try {
        img = require(path);
        return img;
      } catch (e) {
        img = require(fallback);
        return img;
      }
    }
  }
};
</script>
<style>
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1{
  background: #f0f0f0;
}
.box-imagen1 img {
  object-fit: contain;  
}
h4{
  font-size: 22px;
}
</style>