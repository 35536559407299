<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Convocatorias</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Convocatorias</h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row justify-content-between">
        <template v-for="item in items">
          <CardConvocatoria
            :convocatoriaData="item"
            :key="item.entrada_id"
            class="mb-4"
          ></CardConvocatoria>
        </template>
      </div>

      <div id="navegacion" class="text-center row mt-5 mb-5">
        <b-pagination-nav
          use-router
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalRows"
          :per-page="perPage"
          first-number
          last-number
          align="center"
          class="my-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";

import TituloSeccion from "../secciones/TituloSeccion.vue";
import CardConvocatoria from "../convocatorias/CardConvocatoria.vue";

export default {
  components: {
    TituloSeccion,
    CardConvocatoria,
  },
  data() {
    return {
      locations: [],
      items: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
    };
  },
  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Convocatorias",
    htmlAttrs: {
      lang: "es-ES",
    },
  },
  // mounted() {
  //   this.listar();
  // },
  created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.currentPage = 1;
      this.listar();
    } else {
      this.currentPage = this.$route.query.page;
      this.listar();
    }
  },

  watch: {
    $route: function () {
      if (Object.keys(this.$route.query).length === 0) {
        this.currentPage = 1;
        this.listar();
      } else {
        this.currentPage = this.$route.query.page;
        this.listar();
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    listar() {
      var url = "/front/convocatorias?page=" + this.currentPage;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data.data;
          this.totalRows = res.data.data.last_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },    
  },
};
</script>

<style>
</style>